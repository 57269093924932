import React from 'react';
import Box from "@mui/material/Box";
import { DataGridPremium, frFR } from "@mui/x-data-grid-premium";
import moment from 'moment';
const  ResumeCertificatList = ({graphData}) => {
    const mtgl = process.env.REACT_APP_MONTANT_FIXE;
    const columns = [
        {
          field: "ordre",
          headerName: "N°Ordre",
       
          minWidth: 80,
        },
        {
            field: "mois",
            headerName: "Mois",
            minWidth: 180,
        },
        {
            field: "total",
            headerName: "Total",
            minWidth: 180,
            
        },
        {
            field: "montant",
            headerName: "Montant(fg)",
            minWidth: 210,
        },
    ];
 const data = graphData?.map((p,index) => {
    const mois = moment(p.month + "-01").format('MMMM')
     return {
          id:index,
          ordre: index + 1,
          mois:mois.charAt(0).toUpperCase()+ mois.slice(1).toLowerCase(),
          total: p.total,
          montant: parseInt(p.total) * parseInt(mtgl)
     }
 })
  return (
     <Box sx={{ height: 250, width: "100%" }}>
        {data &&
          <DataGridPremium
            rows={data}
            columns={columns}
            density="compact"
            autoPageSize
            pagination
            sx={{ borderRadius: 0 }}
            disableSelectionOnClick
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          />
        }
      </Box>
    
  )
}

export default ResumeCertificatList