import { useContext, useEffect, useRef, useState } from "react";
import { paymentSteps } from "../../utils/texts";
import Stepper from "../../components/Stepper/Stepper";
import StepOne from "../../components/PaymentSteps/StepOne";
import StepTwo from "../../components/PaymentSteps/StepTwo";
import StepThree from "../../components/PaymentSteps/StepThree";
import DocumentTitle from "../../components/DocumentTitle/DocumentTitle";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import { ElementContext } from "../../services/Context/Context";
const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  return [ htmlElRef, setFocus ] 
}
const Payment = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [custumErr,setCustumErr] = useState('');
  const [inputRef, setInputFocus] = useFocus();
  const [paiement,setPaiement] = useState({
    typeClient:'',
    modeExp:'',
    fullName: '',
    tel:'',
    nif:'',
    chassis:'',
    modeImma:'',
    categorieCg: '',
    typeCg:'', 
    typeVignette:'',
    pv: 0,
    cu: 0,
    nbrePlace:0,
    autorisation_id:0,
    expressionCg:'',
    isNextStep:1,
    isIT:false,
    isEP:false,
    isVA:false,
    vignetteExo:false,
    dateExpCg:'',
    ancienNumMat:'',
    pf:0,
    ptra:0,
  });
  const [paiementInfo,setPaiementInfo] = useState({
    categorie:'',
    commission:0,
    vignette:'',
    cartegrise:'',
    categorie_id:0,
    autorisation:'',
    qrcodepath:'',
    reference:'',
    paiement_id:'',
    document:'',
    penalites: 0,

  });
  const{elementsData} = useContext(ElementContext);
 const CtrInputPuissance = (cartegrise_id,cartegorie_id) => {
   const cartegrise = elementsData.typeCarteGrise.filter((c) => parseInt(c.typecg_id) === parseInt(cartegrise_id));
   let expressionErrer = '';//expression de l'erreur
   if(cartegrise.length > 0 && parseInt(cartegorie_id) !== 4 && parseInt(cartegorie_id) !== 5 && parseInt(cartegorie_id) !== 6){
    const signearray = cartegrise[0].signe.split(",");
    const capacitearray  =  cartegrise[0].capacite.split(",");
    let puissance = 0 ;
    if(parseInt(paiement.categorieCg) !== 3)
       puissance = paiement.pf;
     else puissance = paiement.nbrePlace; 

    if(signearray.length === 1){
 
       switch(signearray[0]){
        case '<': 
                if(capacitearray.length === 1){
                   const comparaison = parseInt(puissance) < parseInt(capacitearray[0]);
                   if(!comparaison){
                    if(parseInt(cartegorie_id) !== 3)
                      expressionErrer = "La valeur de la puissance fiscale doit être inférieur à "+capacitearray[0]+ " " +cartegrise[0].unite;
                    else expressionErrer = "La valeur du nombre de place doit être inférieur à "+capacitearray[0];  
                     setInputFocus();
                    }
                 }
                 break;
                   
        case '>':
                if(capacitearray.length === 1){
                  const comparaison = parseInt(puissance) > parseInt(capacitearray[0]);
                  if(!comparaison){
                    if(parseInt(cartegorie_id) !== 3)
                      expressionErrer = "La valeur de la puissance fiscale doit être supérieur à "+capacitearray[0]+ " " +cartegrise[0].unite;
                    else  expressionErrer = "La valeur du nombre de place doit être supérieur à "+capacitearray[0];   
                    setInputFocus();
                  }
                }
                break; 
       case '!': 
  
              if(capacitearray.length > 1){
                  const comparaison = parseInt(puissance) > parseInt(capacitearray[0]) &&  parseInt(puissance) < parseInt(capacitearray[1])  ;
                  if(!comparaison){
                    if(parseInt(cartegorie_id) !== 3)
                      expressionErrer = "La valeur de la puissance fiscale doit être comprise entre "+ capacitearray[0]+ " et " +capacitearray[1]+" " +cartegrise[0].unite;
                    else expressionErrer = "La valeur du nombre de place  doit être comprise entre "+ capacitearray[0]+ " et " +capacitearray[1];
                    setInputFocus();
                  }
                }
              break;            
       
        case '<=': 
        
                if(capacitearray.length === 1){
                    const comparaison = parseInt(puissance) <= parseInt(capacitearray[0]);
                    if(!comparaison){
                      if(parseInt(cartegorie_id) !== 3)
                        expressionErrer = "La valeur de la puissance fiscale doit être inférieur ou égale à "+capacitearray[0] + " " +cartegrise[0].unite;
                      else  expressionErrer = "La valeur du nombre de place doit être inférieur ou égale à "+capacitearray[0] + " " +cartegrise[0].unite; 
                      setInputFocus();
                    }
                  }
                break; 
        case '>=': 
                if(capacitearray.length === 1){
                    const comparaison = parseInt(puissance) >= parseInt(capacitearray[0]);
                    if(!comparaison){
                      if(parseInt(cartegorie_id) !== 3)
                        expressionErrer = "La valeur de la puissance fiscale doit être inférieur ou égale à "+ capacitearray[0]+ " " +cartegrise[0].unite;
                      else expressionErrer = "La valeur du nombre de place doit être inférieur ou égale à "+ capacitearray[0];
                      setInputFocus();
                    }
                  }
                break;                         
                    
       }
    }
    else if(signearray.length > 1){
      switch(cartegrise[0].signe){
        case '>,<=': 
              
               if(capacitearray.length > 1){
                
                  const comparaison = parseInt(puissance) > parseInt(capacitearray[0]) && parseInt(puissance) <= parseInt(capacitearray[1]);
                 
                  if(!comparaison){
                    if(parseInt(cartegorie_id) !== 3)
                      expressionErrer = "La valeur de la puissance fiscale doit être supérieur à "+capacitearray[0]+ " et inférieur ou égale à "+ capacitearray[1] + " " +cartegrise[0].unite;
                   else    expressionErrer = "La valeur du nombre de place doit être supérieur à "+capacitearray[0]+ " et inférieur ou égale à "+ capacitearray[1] ; 
                    setInputFocus();
                  }
                }
                break;
                  
       case '>=,<':
            if(capacitearray.length > 1){
              const comparaison = parseInt(puissance) >= parseInt(capacitearray[0]) && parseInt(puissance) < parseInt(capacitearray[1]);
              if(!comparaison){
                if(parseInt(cartegorie_id) !== 3)
                  expressionErrer = "La valeur de la puissance fiscale doit être supérieur ou égale à "+capacitearray[0]+ " et inférieur à "+ capacitearray[1] + " " +cartegrise[0].unite;
                else expressionErrer = "La valeur du nombre de place doit être supérieur ou égale à "+capacitearray[0]+ " et inférieur à "+ capacitearray[1] + " " +cartegrise[0].unite;  
                setInputFocus();
              }
            }
            break;                     
                   
      }
    }
   }
   return expressionErrer; 
 }

 const location = useLocation();
  const nextStep = () => {
    setCustumErr('');
  if( !paiement.isEP && !paiement.isIT && !paiement.isVA ) {
    setCustumErr("Vous n'avez pas choisi de type de plaque( IT ou EP ).");
    return;
  } 
  if(!paiement.isVA){  
    if(paiement.isNextStep === 1){
        if(parseInt(paiement.typeCg) === 0 && parseInt(paiement.typeVignette) === 0 
          && parseInt(paiement.autorisation_id) === 0){
          setCustumErr("Vous n'avez choisi aucun type de paiement(Vignette ou Carte grise ou autorisation).");return;
        }
      if(paiement.dateExpCg === '' && paiement.isIT){
          setCustumErr("La date d'expiration est obligatoire.");return;
      }else if(moment().diff(paiement.dateExpCg,'days') > 0){
          setCustumErr("La date d'expiration ne peut pas être inférieur à celle d'aujourd'hui.");return;
      }
      const expressionErreur = CtrInputPuissance(paiement.typeCg,paiement.categorieCg);
      if( paiement.pf === '0' && paiement.nbrePlace === 0){
        setCustumErr("Veuillez fournir la puissance fiscale ou le nombre de place.");return;
      }
      else if(expressionErreur !== '' && paiement.categorieCg !== 4){
          setCustumErr(expressionErreur);return;
      } 
      setActiveStep(activeStep + 1);
        if(paiement.isIT)
            setPaiementInfo({...paiementInfo,document:"IT"});
        else if(paiement.isEP)  
            setPaiementInfo({...paiementInfo,document:"EP"}); 
        else  setPaiementInfo({...paiementInfo,document:"Ordinaire"});    
      }
  }
  else
   if(paiement.isNextStep === 1){
     if(!paiement.isEP && !paiement.isVA){
        setCustumErr("Vous n'avez pas choisi de type de plaque(VA ou EP).");
    }
    else if(paiement.isVA){
      setPaiement({...paiement,typeCg:0,typeVignette:0});
      setActiveStep(activeStep + 1);
    }
    else
      setActiveStep(activeStep + 1);
   }
    
  
  }

  const prevStep = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <div className="payment page">
      <DocumentTitle title="Nouveau paiement" />
      <div className="header">
        <h3>Paiement d'immatriculation</h3>
        <p>Suivez les trois étapes suivantes pour créer un nouveau paiement.</p>
      </div>
      <div className="payment-zone">
        <div className="payment-steps">
          <Stepper steps={paymentSteps} activeStep={activeStep} />
        </div>
        <div className="payment-form">
          {activeStep === 1 && <StepOne nextStep={nextStep} prevStep={prevStep} 
            paiement={paiement} setPaiement={setPaiement}
            setCustumErr={setCustumErr}
            custumErr={custumErr}
            inputRef={inputRef}
          />}
          {activeStep === 2 && <StepTwo nextStep={nextStep} prevStep={prevStep} 
            paiement={paiement}
            setPaiement={setPaiement}
            setPaiementInfo={setPaiementInfo}
            paiementInfo={paiementInfo}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          
          />}
          {activeStep === 3 && <StepThree nextStep={nextStep} 
            prevStep={prevStep} 
            paiement={paiement}
            paiementInfo={paiementInfo}
          />}
        </div>
      </div>
    </div>
  );
};

export default Payment;
