import React, { useState } from 'react';
import Api from '../../services/Api';
import { Button } from "@mui/material";
import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";

 const  ToolBarCertificat = ({ filterModel,filterData,isLoading,setIsLoading }) => {
    const [disabled, setDisabled] = useState(true);
    const [ datatoExport,setDatatoExport ] = useState([]);
    const api = new Api();

  return (
    <GridToolbarContainer>
          <GridToolbarFilterButton />  
           <Button
            // startIcon={<SaveAltIcon />}
            // onClick={onExportClick}
            //disabled={disabled}
            >
           Exporter
          </Button>
          {/* <Button onClick={() => setDisabled((state) => !state)}>
            {disabled ? "Enable" : "Disable"} export button
          </Button> */}
        
        </GridToolbarContainer>
  )
}
export default ToolBarCertificat;
