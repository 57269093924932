import React, { useState } from 'react'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle';
import StepOne from '../../components/PaiementCertificatStep/StepOne';
import StepTwo from '../../components/PaiementCertificatStep/StepTwo';
import "./PaiementCertificat.scss";
import { paymentStepsCertificat } from "../../utils/texts"
import Stepper from '../../components/Stepper/Stepper';
import "./PaiementCertificat.scss"
import StepThree from '../../components/PaiementCertificatStep/StepThree';

function PaiementCertificat() {
  const [activeStep, setActiveStep] = useState(1);
  const [ paiement, setPaiement ] = useState({
    departement: '',
    typepaiement: '',
    typeIdentifiant: '',
    nomDemandeur: '',
    prenomDemandeur: '',
    telephoneDemandeur: '',
    emailDemandeur: '',

  });
  const [ selectedDocument,setSelectedDocument ] = useState([]);
  const nextStep = () => {
     if( activeStep <= 2 ){
        setActiveStep(activeStep + 1)
     }
  }
  const prevStep = () => {
    if( activeStep >= 1 ){
         setActiveStep( activeStep - 1 )
    }
  }
  return (
    <div className="payment page">
      <DocumentTitle title="Nouveau paiement" />
      <div className="header">
        <h3>Paiement de certificat de perte</h3>
        <p>Suivez une seule étape  pour créer un nouveau paiement.</p>
      </div>
      <div className="payment-zone">
        <div className="payment-steps">
          <Stepper steps={paymentStepsCertificat} activeStep={activeStep} />
        </div>
        <div className="payment-form">
           { activeStep === 1 && 
             <StepOne nextStep={nextStep} 
               activeStep={activeStep}
               setActiveStep={setActiveStep}
               setPaiement={setPaiement}
               paiement={paiement}
               selectedDocument={selectedDocument}
               setSelectedDocument={setSelectedDocument}
             />
           } 
           { activeStep === 2 &&
              <StepTwo
                 nextStep={nextStep}
                 prevStep={prevStep}
                 paiement={paiement}
                 activeStep={activeStep}
                 setPaiement={setPaiement}
                 setActiveStep={setActiveStep}
              />
           }
           { activeStep === 3 &&
              <StepThree 
                 paiement={paiement}
                 setPaiement={setPaiement}
              />
           }
        </div>    
      </div>  
    </div>  
  )
}

export default PaiementCertificat