import React from 'react';
import "./CardTable.scss";
function CardTable({ data }) {
  return (
    <div className="cart-table" style={{ overflowX:'unset'}}>
       <table style={{ minWidth: '620px'}}>
          <thead className="table-header">
            <tr>
              <th>Description</th>
              <th>Categorie</th>
              <th>Prix (en fg)</th>
            </tr>
          </thead>
          <tbody>
             <tr>
                <td>C V VEHICULE LEGERS</td>
                <td>{ data?.categorie }</td>
                <td>{ data?.prix }</td>
             </tr>
          </tbody>
        </table>  
    </div>
  )
}

export default CardTable;