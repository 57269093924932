import React from 'react';
import { useForm } from "react-hook-form";
export default function StepOne({ nextStep,paiement,setPaiement }) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({
        defaultValues: {
          paiement,
        },
      });  
  const handleInput = (e) => {  
    setPaiement({...paiement,[e.target.name]:e.target.value})
  } 
  return (
    <div className="step-component">
      <h4>Informations du client et du véhicule</h4>
      <form onSubmit={handleSubmit(nextStep)}>
         <div className="input-group">
            <label className='obligatoire'>
             Type de client
             <select
              name="typeClient"
              id="typeClient"
              {...register("typeClient", {
                onChange: (e) => {
                  handleInput(e);
                },
                validate: (value) => value !== "",
              })}
              value={paiement.typeClient}
             >
              <option value="" key={"typeClient"}>
                Selectionnez le type de client
              </option>
              <option value="Particulier" key={"particulier"}>
                Particulier
              </option>
               <option value="Société" key={"societe"}>
                Société
              </option>
              <option value="Gouvernement" key={"Gouvernement"}>
                Gouvernement
              </option>
            </select>
            {errors.typeClient && (
              <span className="error-msg">Ce champ est obligatoire.</span>
            )}
            </label> 
            <label className='obligatoire'>
              {paiement.typeClient=== "" && " Client"}  
              {paiement.typeClient === "Société" && 
                "Nom de la société"}
              {paiement.typeClient === "Particulier" &&  "Prénom et Nom"}
              {paiement.typeClient === "Gouvernement" &&  "Nom du ministère ou structure"}
              <input
                value={paiement.nomComplet}
                type="text"
                name="nomComplet"
                key="nomComplet"
                placeholder={paiement.typeClient === "Société"? "Nom de la société"
                    : paiement.typeClient === 'Particulier' ? "Prénom et Nom de la Personne":paiement.typeClient === 'Gouvernement' && 'Nom du ministère ou structure'?paiement.typeClient === "" : "Choisir le type de client"
                }
                id="nomComplet"
                {...register("nomComplet", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  required: true,
                  maxLength: 150,
                  minLength: 2,
                })}
              />
              {errors.nomComplet && errors.nomComplet?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.nomComplet && errors.nomComplet?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 2 caractères.
                </span>
              )}
              {errors.nomComplet && errors.nomComplet?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 150 caractères.
                </span>
              )}
            </label>     
         </div>
         <div className='input-group'>
           <label className='obligatoire'>
              Téléphone
              <input 
               type='tel'
               name='telephone' 
               placeholder="Numéro de téléphone(ex: 620000000)"
               value={paiement.telephone}
               {...register("telephone", {
                onChange: (e) => {
                  handleInput(e);
                },
                required: true,
                maxLength: 9,
                minLength: 5,
                pattern: /6[0-9]{8}$/g,
              })}
              />
              {errors.telephone && errors.telephone?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.telephone && errors.telephone?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 5 caractères.
                </span>
              )}
              {errors.telephone && errors.telephone?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 9 caractères.
                </span>
              )}
              {errors.telephone && errors.telephone.type === "pattern" && (
                <span role="alert" className="error-msg">
                  Format invalide.ex: 620000000
                </span>
              )}
          
           </label>
           <label>
             Email
             <input type='text' name="email" placeholder='Email (ex:example@example.com)' 
               value={ paiement.email }
               {...register("email", {
                onChange: (e) => {
                  handleInput(e);
                },
                required: false,
                // maxLength: 9,
                // minLength: 5,
               pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              })}
             />
              {errors.email && errors.email.type === "pattern" && (
                <span role="alert" className="error-msg">
                  Format invalide.ex: example@example.com
               </span>
              )}
           </label>
         </div>
         {/* <div className='input-group'>
           <label className='obligatoire'>
              Marque du véhicule
              <select
                name="marque"
                id="marque"
                {...register("marque", {
                    onChange: (e) => {
                    handleInput(e);
                    },
                    validate: (value) => value !== "",
                })}
                value={paiement.marque}
              >
             </select>
             {errors.marque && (
              <span className="error-msg">Ce champ est obligatoire.</span>
            )}    
           </label> 
           <label className='obligatoire'>
             Modèle du véhicule
             <select
                name="modele"
                id="modele"
                {...register("modele", {
                    onChange: (e) => {
                    handleInput(e);
                    },
                    validate: (value) => value !== "",
                })}
                value={paiement.modele}
                >
                </select>
                {errors.modele && (
                 <span className="error-msg">Ce champ est obligatoire.</span>
                )}  
           </label>
         </div> */}
         <div className='input-group'>
           <label className='obligatoire'>
              Numéro de Chassis
              <input
                value={paiement.chassis}
                type="text"
                name="chassis"
                key="chassis"
                {...register("chassis", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    required: true,
                    maxLength: 150,
                    minLength: 2,
                  })}
               /> 
              {errors.chassis && errors.chassis?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.chassis && errors.chassis?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 2 caractères.
                </span>
              )}
              {errors.chassis && errors.chassis?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 150 caractères.
                </span>
              )}
           </label> 
           <label>
              Numéro de Immatriculation
              <input
                value={paiement.numImmat}
                type="text"
                name="numImmat"
                key="numImmat"
                placeholder="ex: AA-0001-02"
                {...register('numImmat', {
                onChange: (e) => {
                    handleInput(e)
                },
                pattern:{
                    value:/^[a-zA-z]{1,3}(-){1}[0-9].{0,3}(\s|-){1}([a-zA-z]|[0-9]){1,3}|\s[0-9]{1,3}$/,
                    message:"Format Incorrect.ex: AA-0001-02"
                   }
                 },
               )}
                
               /> 
              {errors.numImmat && errors.numImmat?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.numImmat && errors.numImmat?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 2 caractères.
                </span>
              )}
              {errors.numImmat && errors.numImmat?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 150 caractères.
                </span>
              )}
           </label> 
         </div>
         <div className='input-group'>
            <label>
                Catégorie
                <select
                  name='categorie'
                  {...register("categorie", {
                    onChange: (e) => {
                    handleInput(e);
                    },
                    validate: (value) => value !== "",
                  })}
                  value={paiement.categorie}
                 >
                 <option value="">---</option>
                 <option value="dfeer">djdjjd</option>
                </select>
                {errors.categorie && (
                 <span className="error-msg">
                    Ce champs est obligatoire.
                 </span>
              )}
            </label>
            <label>
                Prix
                <input 
                   disabled={true}
                   value={paiement.prix}
                />
            </label>
         </div>
         <div className="buttons">
           <button type="submit">Suivant</button>
         </div>   
      </form>
    </div>  
  )
}
