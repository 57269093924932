import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2';
import "./BarCharCertificat.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );
function BarCharCertificat({graph}) {
//   const [ data,setData ] = useState();
//   if( graph?.length > 0 ) {
    const data = {
        labels: graph?.map((p) => {
         const mois = moment(p.month + "-01").format('MMMM')
           return mois.charAt(0).toUpperCase()+ mois.slice(1).toLowerCase();
        }),
        //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Totaux Certificats',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)',
            data: graph?.map((p) => {
              return p?.total
           }),
          },
        ],
      };
    
   // }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Totaux des Certificats de Perte',
      },
    },
  };
//   useEffect(() => {
 
//   },[graph])  
  return <Bar data={data} options={options}  width={800}
  height={520} />
}

export default BarCharCertificat