import React from 'react'
import CardTable from './CardTable/CardTable';
import { ToWords } from "to-words";
import Erreurs from "../Erreurs/Erreurs";
import "./_Step.scss"
import { formatStringNumber,objecttoFormData } from "../../services/Helpers/fonctions";
function StepTwo({ nextStep,prevStep,paiement }) {
   const toWords = new ToWords({
        localeCode: "fr-FR",
        converterOptions: {
          currency: false,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
        },
   });  
  const payer = () => {
    nextStep();
  }  
  return (
    <div className="step-component">
       <h4>Détails du Paiement</h4>
       <div className="table">
         <CardTable data={paiement} />  
       </div> 
       <div className="total">
          <span>Total</span>
          <div className='chiffre'>
            <strong>{ formatStringNumber(paiement.prix) } fg</strong>
            <strong>{ toWords.convert(paiement.prix) }</strong> Francs Guinéens.
          </div>
        
       </div> 
       <div className="buttons">
        <button className="secondary" onClick={prevStep}>
          Précedent
        </button>
        <button className="primary" onClick={payer}>
          Payer
        </button>
      </div>
    </div>
  )
}

export default StepTwo;