import React, { useState } from 'react'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle'
import Stepper from '../../components/Stepper/Stepper';
import { paymentSteps } from "../../utils/texts";
import StepOne from '../../components/PaiementStepVadico/StepOne';
import StepTwo from '../../components/PaiementStepVadico/StepTwo';
import StepThree from '../../components/PaiementStepVadico/StepThree';

function PaiementVadico() {
  const [activeStep, setActiveStep] = useState(1);
  const [paiement,setPaiement] = useState({
    typeClient: '',
    nomComplet:'',
    adresse:'',
    marque:'',
    modele: '',
    chassis:'',
    numImmat: '',
    categorie:'',
    telephone:'',
    email:'',
    prix: 0,

  })
  const nextStep = () => {
      setActiveStep((active) => activeStep >= 1 ? active + 1:activeStep)
  }
  const prevStep = () => {
    setActiveStep((active) => activeStep > 1 ? active - 1:activeStep)
  }
  return (
     <div className="payment page">
       <DocumentTitle title="Nouveau paiement" />
       <div className="header">
            <h3>Paiement de contrôle technique des véhicules</h3>
            <p>Suivez les trois étapes suivantes pour créer un nouveau paiement.</p>
       </div>
       <div className="payment-zone">
         <div className="payment-steps">
            <Stepper steps={paymentSteps} activeStep={activeStep} />
          </div>
          <div className="payment-form">
            {activeStep === 1 && <StepOne nextStep={nextStep}  paiement={paiement} setPaiement={setPaiement} />} 
            {activeStep === 2 && <StepTwo nextStep={nextStep} prevStep={prevStep} paiement={paiement} setPaiement={setPaiement} />}
            {activeStep === 3 && <StepThree paiement={paiement} />}  
          </div>
       </div> 
     </div>   
  )
}

export default PaiementVadico