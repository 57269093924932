import React,{ useState ,useEffect}  from "react";
import { isEmptyDict } from "../../services/Helpers/fonctions";

export default function Erreurs({validation})
{
  /*  var list = props.validation.map((item) => {
        <li key={item}>{item}</li>
   })  */ 
   var data = Array.isArray(validation) ? validation[0] : validation; 
  //  console.log(data)
    return (
      <>
      {(typeof data === 'object' && !isEmptyDict(data))?
        <div className="alert alert-danger print-error-msg"> 
            <ul style={{listStyle :'number',paddingLeft:'5px'}}>
               {
                Object.entries(data).map(([key, value]) => (
                  ( key !== 'field' ) && <li key={key} className = 'error-msg'>{value}</li>
                
            ))
             }
            </ul>
        </div>:null
        }
      </>
      );
   
}
