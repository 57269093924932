import React, { useRef } from 'react'
import InvoiceDetailsVadico from '../../components/InvoiceDetailsVadico/InvoiceDetailsVadico';
import { useReactToPrint } from 'react-to-print';
const PaymentDetailsVadico = ({ paiement }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  console.log(paiement)
  return (
    <div className='payment-invoice page'>
       <div className="left">
           {/* {paiement && */}
            <button className="print-btn" onClick={handlePrint}>
              Imprimer
            </button>
        </div>    
       <InvoiceDetailsVadico paiement={paiement} ref={componentRef} />
    </div>
  )
}

export default PaymentDetailsVadico