import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { TDocument } from '../../utils/Data'
import { FirstUpperCase, StringToUpperCase } from '../../services/Helpers/fonctions';
function StepOne({ nextStep,activeStep,setActiveStep ,paiement,setPaiement,selectedDocument,setSelectedDocument}) {
  const { register,handleSubmit,reset,formState: { errors },} = useForm({defaultValues: {},});
  const [ pattern,setPattern ] = useState({
    pattern:'',
    message:''
  })
  const handleInput = (e) => {
     const re = /^[0-9\b]+$/;
     if( e.target.name === "departement" ){
        setPaiement({...paiement,[e.target.name]:e.target.value});
        setSelectedDocument(TDocument.filter((p) => p.departement === e.target.value ));
     }
     else if( e.target.name === "telephoneDemandeur" ){
       if( e.target.value === '' || re.test(e.target.value) ){
          setPaiement({...paiement,[e.target.name]:e.target.value});
       }
     }
     else if(e.target.name === 'typeIdentifiant'){
      if( e.target.value === 'numeroImmatriculation'){
        setPattern(({...pattern, 
            pattern:/^[a-zA-z]{1,3}(-){1}[0-9].{0,3}(\s|-){1}([a-zA-z]|[0-9]){1,3}|\s[0-9]{1,3}$/,
            message:'Format Incorrect.ex:AA-0001-02'
          }));
        }
        else{
          setPattern(({...pattern, 
            pattern:'',
            message:''
          }));
        }
        setPaiement({...paiement,[e.target.name]:e.target.value});
     }
     else{
       setPaiement({...paiement,[e.target.name]:e.target.value});
       
      }

     
  }
  const submitNext = () => {
       setActiveStep(activeStep + 1)
  }
  return (
    <div className="step-component">
       <h4>Informations</h4>
       <form onSubmit={handleSubmit(submitNext)}>
         <div className='input-group'>
            <label>
               Departement
               <select name="departement"
                  value={paiement.departement}
                  {...register("departement", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    validate: (value) => value !== "",
                  })}
                >
               <option value="">--</option>
               {TDocument.map((item) => (<option value={item.departement} key={item.id}>{  item.departement }</option>))}
               </select>
               {errors.departement && (
                 <span className="error-msg">Ce champ est obligatoire.</span>
               )}
            </label>
           <label>
            Type de document perdu
             <select name="typedocument"
                  value={paiement.typedocument}
                  {...register("typedocument", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    validate: (value) => value !== "",
                  })}
                >
                <option value="">--</option>
                { selectedDocument.length > 0 && selectedDocument[0].types.map((item,index) =>(<option value={item} key={index + Math.random()}>{item}</option>)) }  
               </select>
               {errors.typedocument && (
                 <span className="error-msg">Ce champ est obligatoire.</span>
               )}
            </label>
         </div>
         {paiement?.departement === "Direction nationale de la Securité Routière" &&
          <div className='input-group'>
           <label>
               Type d'identifiant
               <select name="typeIdentifiant"
                  value={paiement.typeIdentifiant}
                  {...register("typeIdentifiant", {
                    onChange: (e) => {
                      handleInput(e);
                    },
                    validate: (value) => value !== "",
                  })}
                >
               <option value="">--</option>
               <option value="numeroImmatriculation">Numéro d'immatriculation</option>
               <option value="numeroDocument">Numéro sur le Document</option>
               <option value="numeroChassis">Numéro de Chassis</option>
               </select>
               {errors.typeIdentifiant && (
                 <span className="error-msg">Ce champ est obligatoire.</span>
               )}
            </label>
            <label>Identifiant
               <input type='text' name="identifiant" 
                style={{ textTransform:'uppercase'}}
                value={paiement.identifiant}
                {...register("identifiant", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  required: true,
                  minLength: 2,
                  pattern: {
                    value: pattern.pattern,
                    message: pattern.message
                  }
                })}
               />
              {errors.identifiant && errors.identifiant?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.identifiant && errors.identifiant?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 5 caractères.
                </span>
              )}
              {errors.identifiant && errors.identifiant.type === "pattern" && (
                <span role="alert" className="error-msg">
                  { errors.identifiant.message }
                </span>
              )}
            </label>
          </div>
         }
         <div className='input-group'>
            <label>
                Prénom
                <input type='text' name='prenomDemandeur' placeholder='Prénom du demandeur'
                   value={FirstUpperCase(paiement?.prenomDemandeur)}
                    {...register("prenomDemandeur", {
                     onChange: (e) => {
                        handleInput(e);
                    },
                    required: true,
                    maxLength: 150,
                    minLength: 2,
                  })}
                />
              {errors.prenomDemandeur && errors.prenomDemandeur?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.prenomDemandeur && errors.prenomDemandeur?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 2 caractères.
                </span>
              )}
              {errors.prenomDemandeur && errors.prenomDemandeur?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 150 caractères.
                </span>
              )}
            </label>
            <label>
               Nom
               <input type='text' name='nomDemandeur' placeholder='Nom du demandeur'
              
                value={ StringToUpperCase(paiement.nomDemandeur)}
                {...register("nomDemandeur", {
                  onChange: (e) => {
                     handleInput(e);
                 },
                 required: true,
                 maxLength: 150,
                 minLength: 2,
                })}
               />
             {errors.nomDemandeur && errors.nomDemandeur?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.nomDemandeur && errors.nomDemandeur?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 2 caractères.
                </span>
              )}
              {errors.nomDemandeur && errors.nomDemandeur?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 150 caractères.
                </span>
              )}
            </label>
         </div>
         <div className='input-group'>
           <label>
              Téléphone
              <input type="tel" name='telephoneDemandeur' 
              placeholder="Numéro de téléphone(ex: 620000000)"
              id="telephoneDemandeur"
              value={paiement.telephoneDemandeur}
              {...register("telephoneDemandeur", {
                onChange: (e) => {
                  handleInput(e);
                },
                required: true,
                maxLength: 9,
                minLength: 5,
                pattern: /6[0-9]{8}$/g,
              })}
            />
              {errors.telephoneDemandeur && errors.telephoneDemandeur?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
              {errors.telephoneDemandeur && errors.telephoneDemandeur?.type === "minLength" && (
                <span className="error-msg">
                  Ne peut pas être inférieur à 5 caractères.
                </span>
              )}
              {errors.telephoneDemandeur && errors.telephoneDemandeur?.type === "maxLength" && (
                <span className="error-msg">
                  Ne peut pas être supérieur à 9 caractères.
                </span>
              )}
              {errors.telephoneDemandeur && errors.telephoneDemandeur.type === "pattern" && (
                <span role="alert" className="error-msg">
                  Format invalide.ex: 620000000
                </span>
              )}
           </label>
           <label>
             Email
             <input type='text' name="emailDemandeur" placeholder='Email (ex:example@example.com)' 
               value={ paiement.emailDemandeur }
               {...register("emailDemandeur", {
                onChange: (e) => {
                  handleInput(e);
                },
                required: false,
                // maxLength: 9,
                // minLength: 5,
               pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              })}
             />
              {errors.emailDemandeur && errors.emailDemandeur.type === "pattern" && (
                <span role="alert" className="error-msg">
                  Format invalide.ex: example@example.com
               </span>
              )}
           </label>
         </div>
         <div className="buttons">
           <button type="submit">Suivant</button>
         </div>
       </form>
    </div>
  )
}

export default StepOne